/** @jsx jsx */
import { jsx } from 'theme-ui';
import Layout from '../components/Layout';
import HeaderWithImage from '../components/Common/HeaderWithImage';
import Intro from '../components/Consultation/Intro';
import Help from '../components/Common/Help';
import { Seo } from '../components/Seo';
import Form from '../components/Common/Form';


const FreeConsultationPage = () => {
  return (
    <Layout>
      <Seo
        title="Free Consultations | National Embryo Donation Center"
        description="Knoxville Full Service Fertility, IVF, and Reproductive Surgery Center. Specializing in Infertility, Testing, and Embryo Adoption. Serving East Tennessee."
      />
      <div>
        <blockquote>
          <b><p sx={{
              //color: '#F7A496',
              color: '#FFFFFF',
              //color: 'yellow',
              textAlign: 'center',
              fontSize: '1.25rem',
              background: '#61A19E',
              //textTransform: 'uppercase',
            }}>PLEASE NOTE: Do not request a free consultation if you already have an appointment scheduled with us! </p>
          <p sx={{
              //color: '#F7A496',
              color: '#FFFFFF',
              //color: 'yellow',
              textAlign: 'center',
              fontSize: '1.25rem',
              background: '#61A19E',
            }}>These consultations are only for people who have concerns about infertility and/or embryo adoption and have questions about whether or how to proceed.</p>
          </b>
        </blockquote>
        <HeaderWithImage
          title="Free Consultations"
          //description="We're Here to Help."
          image={'/images/Konsults_with_Keenan.png'}
          //background={''}
          //background={'/images/header/chayene-rafaela-nGwkIZFelko-unsplash.png'}
        />
        <Intro />
        <Form formUrl="https://usebasin.com/f/3d8cd9ad92f7" />
        <Help />
      </div>
    </Layout>
  );
};

//https://usebasin.com/form/d2e3d293ced1/view/340d2b7ade53
//https://usebasin.com/f/d2e3d293ced1

export default FreeConsultationPage;
