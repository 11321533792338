/** @jsx jsx */
import { jsx } from 'theme-ui';
import SectionHeader from '../Common/SectionHeader';

const Intro = () => {
  return (
    <section>
      <div className="text-content">
        <SectionHeader heading="Free Consultations" styles={{ pt: [null, null, null, '0.5rem'] }} />
  
      <div
        sx={{
          maxWidth: '1400px',
          margin: 'auto',
          padding: ['1rem', null, null, '2rem'],
          gap: '0.75rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          variant: 'text.normal',
          fontWeight: 400,
          color: '#4D4F60',
        }}
      >
        <p>
        Not sure if embryo adoption with the NEDC is right for you? Have questions about infertility or fertility treatment in general?  Would you like a second opinion?
        </p>
        <p>
        NEDC President & Medical Director Dr. Jeffrey Keenan wants to help. Dr. Keenan provides complimentary 15-minute telemedicine consultations to hear your story and walk through your questions.
        </p>
        <p>
         Sound like what you’ve been looking for? Fill out the form below and someone will be in touch shortly to schedule your free 15-minute telemedicine consultation with Dr. Keenan.
        </p>
        </div>
      </div>
    </section>
  );
};

export default Intro;
