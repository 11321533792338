/** @jsx jsx */
import * as React from 'react';
import { jsx } from 'theme-ui';
import { useForm, ValidationError } from '@formspree/react';

const Form = ({ formUrl = 'https://usebasin.com/f/3d8cd9ad92f7' }) => {
  // const [state, handleSubmit] = useForm('mrgdabgl');
  // if (state.succeeded) {
  //   return (
  //     <section
  //       sx={{
  //         bg: '#FFE664',
  //         py: '4rem',
  //       }}
  //     >
  //       <div
  //         sx={{
  //           px: ['1rem', null, null, '2rem'],
  //           py: ['1rem', null, null, '2rem'],
  //           maxWidth: ['528px', null, null, '576px'],
  //           margin: 'auto',
  //           p: {
  //             variant: 'text.lg',
  //             display: 'flex',
  //             flexDirection: 'column',
  //             justifyContent: 'center',
  //             gap: '0.25rem',
  //             textAlign: 'center',
  //           },
  //         }}
  //       >
  //         <p>Your form has been submitted!</p>
  //       </div>
  //     </section>
  //   );
  // }

  return (
    <section
      sx={{
        bg: '#4B938EE5',
        color: 'black',
        py: '4rem',
        scrollMarginTop: 100,
      }}
      id="form-section"
    >
      <div
        sx={{
          p: ['1rem', null, null, '2rem'],
          maxWidth: ['528px', null, null, '576px'],
          margin: 'auto',
          label: {
            variant: 'text.normal',
            display: 'flex',
            flexDirection: 'column',
            gap: '0.25rem',
          },
        }}
      >
        <form action={formUrl} method="POST">
          <div sx={{ display: 'flex', gap: '2rem', flexWrap: 'wrap' }}>
            <div
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                width: '100%',
                'input, textarea, select': {
                  border: '1px solid #ccc',
                  padding: '1rem',
                  borderRadius: '0.5rem',
                },
              }}
            >
              <label htmlFor="firstName">
                First Name
                <input id="firstName" type="text" name="firstName" />
              </label>
              {/* <ValidationError prefix="First Name" field="firstName" errors={state.errors} /> */}
              <label htmlFor="lastName">
                Last Name
                <input id="lastName" type="text" name="lastName" />
              </label>
              {/* <ValidationError prefix="Last Name" field="lastName" errors={state.errors} /> */}
              <label htmlFor="emailAddress">
                Email Address
                <input id="emailAddress" type="email" name="emailAddress" />
              </label>
              {/* <ValidationError prefix="Email" field="emailAddress" errors={state.errors} /> */}
              <label htmlFor="phoneNumber">
                Phone
                <input id="phoneNumber" type="tel" name="phoneNumber" />
              </label>
              {/* <ValidationError prefix="Phone" field="phoneNumber" errors={state.errors} /> */}

              <div
                sx={{
                  background: '#343753',
                  maxWidth: ['528px', null, null, '576px'],
                  textAlign: 'center',
                  borderRadius: '8px',
                  mt: '1.5rem',
                  color: 'white',
                  transition: 'all 0.3s',
                  '&:hover': {
                    opacity: '0.8',
                  },
                }}
              >
                <button
                  type="submit"
                  //    disabled={state.submitting}
                  sx={{
                    all: 'unset',
                    boxSizing: 'border-box',
                    width: '100%',
                    cursor: 'pointer',
                    p: '1rem',
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Form;
